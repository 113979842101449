
import React, { Component } from 'react';
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    Box, Container, Dialog, DialogActions, DialogContent,
    DialogTitle, TextField, Button,
} from '@material-ui/core';
import MaterialTable from "material-table";
import { categoryService } from '../../../_services';
import JoditEditor from "jodit-react";
import IntlMessages from 'util/IntlMessages';
import { SmallTitleBar } from 'components/GlobalComponents';
class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateBtn: false,
            dialog: false,
            title: '',
            description: '',
            errors: {},
            columnDefs: [
                {
                    title: 'Kategoriename',
                    field: 'title',
                    width: 175,

                },
                {
                    title: 'Beschreibung',
                    render: (rowData) => (<div dangerouslySetInnerHTML={{ __html: rowData.description }}></div>),
                    width: 175,
                }
            ],
            rowData: [],
        };


    }
    componentDidMount() {
        this.getCategories()
    }
    getCategories() {
        categoryService.getAll()
            .then(
                result => {
                    this.setState({ rowData: result.categories });
                },
                error => {
                    console.log('error', error)
                }
            );
    }



    deleteCategory(rowData) {
        categoryService.remove(rowData._id).then(
            (result) => {
                this.getCategories()
            },
            (error) => {
                console.log('error', error)
            }
        )
    }

    // on change value of input
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onChangeDescription = description => {
        this.setState({ description: description });
    }



    // add product
    addProduct() {
        if (this.validate()) {
            let object = this.initCategoryObject();
            categoryService.create(object).then(results => {
                this.setState({ dialog: false })
                this.getCategories()
            });
        }
    }
    openEditModal(data) {
        this.setState({ updateBtn: true });
        this.setState({
            id: data._id,
            title: data.title,
            description: data.description
        })
        this.setState({ dialog: true })
    };
    openAddModal() {
        this.setState({
            dialog: true,
            updateBtn: false,
            title: '',
            description: '',
        })
    }

    // update product
    updateProduct() {
        if (this.validate()) {
            let object = this.initCategoryObject();
            categoryService.update(this.state.id, object).then(results => {
                this.setState({ dialog: false })
                this.getCategories()
            });
        }
    }

    // init category object
    initCategoryObject() {
        let object = JSON.stringify({
            title: this.state.title,
            description: this.state.description,
        })
        return (object)
    }

    // form validation
    validate() {
        let isValid = true
        let errors = {};
        if (!this.state.title) {
            errors["title"] = "Bitte geben Sie den Titel ein.";
            isValid = false
        }
        this.setState({
            errors: errors
        });
        return isValid
    }

    render() {

        return (
            <div className="tables-wrapper">
                <SmallTitleBar title={<IntlMessages id="sidebar.categories" />} />
                <Container maxWidth='lg'>
                    <Box px={{ xs: '12px', lg: 0 }} className='page-space-top'>

                        <div
                            style={{ height: '100%', width: '100%' }}
                            className='ag-fresh'>
                            <MaterialTable
                                localization={{
                                    header: { actions: 'Aktion' }, toolbar: { exportName: 'Als CSV exportieren', exportTitle: 'Firmendaten exportieren', searchPlaceholder: 'suche' }, body: { emptyDataSourceMessage: 'Keine anzuzeigenden Datensätze' }, pagination: {
                                        labelDisplayedRows: '{from} von {to} ',
                                        labelRowsSelect: 'Zeilen',
                                        showColumnsTitle: 'Zeige Spalten',
                                        showColumnsAriaLabel: 'Zeige Spalten',
                                        labelRowsPerPage: 'Zeilen pro Seite:',
                                        firstAriaLabel: 'Erste Seite',
                                        firstTooltip: 'Erste Seite',
                                        previousAriaLabel: 'Vorherige Seite',
                                        previousTooltip: 'Vorherige Seite',
                                        nextAriaLabel: 'Nächste Seite',
                                        nextTooltip: 'Nächste Seite',
                                        lastAriaLabel: 'Letzte Seite',
                                        lastTooltip: 'Letzte Seite'
                                    },
                                }}

                                title={<IntlMessages id="sidebar.categories" />}

                                columns={this.state.columnDefs}
                                data={this.state.rowData}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'kategory hinzufügen',
                                        isFreeAction: true,
                                        onClick: () => { this.openAddModal() }

                                    },
                                    rowData => ({
                                        icon: 'edit',
                                        tooltip: 'kategory aktualisieren',
                                        onClick: (event, rowData) => { this.openEditModal(rowData) }
                                    }),
                                    rowData => ({
                                        disabled: rowData._id === '623af8dc9db29f7dfbce8be6' || rowData._id === "6245738371255a502ad4b516",

                                        icon: 'delete',
                                        tooltip: 'kategory löschen',
                                        iconProps: { color: "primary", className: "material-icons-outlined" },
                                        onClick: (event, rowData) =>
                                            this.deleteCategory(rowData),
                                    }),
                                ]
                                }
                                options={{
                                    actionsColumnIndex: -1,
                                    search: true,
                                }} />
                        </div>

                    </Box>
                </Container>
                {/* Add - edit product dialog  */}
                <Dialog
                    open={this.state.dialog}
                    onClose={() => this.setState({ dialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className="dialog-header" id="alert-dialog-title">{"Neue Kategorien"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <TextField margin="dense" id="title" name="title" value={this.state.title} label="Titel" type="text" fullWidth onChange={this.onChange} />
                        <div className="text-danger">{this.state.errors.title}</div>
                        <br />
                        <label>Beschreibung</label>
                        <JoditEditor
                            name="description"
                            value={this.state.description}
                            onChange={this.onChangeDescription}
                        />




                    </DialogContent>
                    <DialogActions className="dialog-footer">
                        <Button className="thm-btn" onClick={() => this.setState({ dialog: false })}>
                            Abbrechen
                        </Button>
                        {
                            this.state.updateBtn ?
                                <Button onClick={() => this.updateProduct()} variant="outlined" className="thm2-btn" autoFocus>
                                    aktualisieren
                                </Button>
                                :
                                <Button onClick={() => this.addProduct()} variant="outlined" className="thm2-btn" autoFocus>
                                    Hinzufügen
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
export default Categories