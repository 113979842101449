import { combineReducers } from 'redux'
import AuthUserReducer from './AuthUserReducer'
import AppSettingsReducer from './AppSettingsReducer'
import UserSettingsReducer from './UserSettingsReducer'
import MenuListReducer from './MenuListReducer'



const reducers = combineReducers({
	authUser: AuthUserReducer,
	settings: AppSettingsReducer,
	UserSettingsReducer:UserSettingsReducer,	
	menuListReducer:MenuListReducer
});

export default reducers;