import {
	LOGIN_EMAIL_CHANGED,
	LOGIN_PASSWORD_CHANGED,
	LOGOUT_USER,
	JWT_LOGIN_REQUEST,
	JWT_LOGIN_SUCCESS,
	JWT_LOGIN_FAILURE
} from 'actions/Types'

/**
 * Initial auth user
 */
const INIT_STATE = {
	user: localStorage.getItem("smav_user"),
	loading: false,
	email: '',
	password: '',
	error: ''
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case LOGIN_EMAIL_CHANGED:
			return { ...state, email: action.payload };

		case LOGIN_PASSWORD_CHANGED:
			return { ...state, password: action.payload };

		case JWT_LOGIN_REQUEST:
            return { ...state, loading: true, loggingIn: true, user: action.payload };

        case JWT_LOGIN_SUCCESS:
            return { ...state, loading: false, loggingIn: true, user: action.payload };

        case JWT_LOGIN_FAILURE:
            return { ...state, loading: false, loggingIn: true, user: action.payload };

		case LOGOUT_USER:
			return { ...state, user: null }

		default: return { ...state };
	}
}