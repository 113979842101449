export const OrderService = {
    allOrders,
    oderDetails,
    updateStatus,
    sendInvoice,
    getByCustomer,
    initInvoiceNumber,
    initOrderNumber,
    createOrder
};

function initInvoiceNumber() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/order/initInvoiceNumber`, requestOptions).then(handleResponse);

}
function initOrderNumber() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/order/initOrderNumber`, requestOptions).then(handleResponse);

}
function oderDetails(order_id) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/order/details/${order_id}`, requestOptions).then(handleResponse);

}

function updateStatus(order_id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: object
    };

    return fetch(`${process.env.REACT_APP_API_URL}/order/update/${order_id}`, requestOptions).then(handleResponse);

}

function createOrder(object) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: object
    };

    return fetch(`${process.env.REACT_APP_API_URL}/order/create`, requestOptions).then(handleResponse);

}
function allOrders() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${process.env.REACT_APP_API_URL}/order/all`, requestOptions).then(handleResponse);
}

function sendInvoice(order_id) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${process.env.REACT_APP_API_URL}/order/sendInvoice/${order_id}`, requestOptions).then(handleResponse);

}
function getByCustomer(customer_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/order/all/${customer_id}`, requestOptions)
        .then(handleResponse)

}
// handle response
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}