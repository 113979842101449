import jwt_decode from "jwt-decode";
export const userService = {
   login,
   refreshToken,
   getById,
   decodeToken,
   update,
   getId,
   resetPasswordRequest,
   resetPassword,
   getAll,
   add,
   remove,
   getRoles

};
// get  roles
function getRoles() {
   const requestOptions = {
      method: 'get',
   }
   return fetch(
      `${process.env.REACT_APP_API_URL}/roles/all`,
      requestOptions
   ).then(handleResponse)
}

function getAll() {
   const requestOptions = {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/getAll`, requestOptions)
      .then(handleResponse)

}

function resetPasswordRequest(email) {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/resetPasswordRequest`, requestOptions)
      .then(handleResponse)
      .then(user => {
         return user;
      });
}

function resetPassword(object) {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(object)
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/resetPassword`, requestOptions)
      .then(handleResponse)

}
function login(email, password) {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/login`, requestOptions)
      .then(handleResponse)
      .then(user => {
         // store user details and jwt token in local storage to keep user logged in between page refreshes
         localStorage.setItem('smav_user', JSON.stringify(user));

         return user;
      });
}

function getById(user_id) {
   const requestOptions = {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/getById/${user_id}`, requestOptions)
      .then(handleResponse)

}
function refreshToken() {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // body: JSON.stringify({ email, password })
   };
   return fetch(`http://localhost:4000/users/refreshToken`, requestOptions)
      .then(handleResponse)
      .then(user => {
         // store user details and jwt token in local storage to keep user logged in between page refreshes
         if (!localStorage.getItem('smav_user') === null) {
            localStorage.setItem('smav_user', JSON.stringify(user));
         }
         return user;
      });
}

function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         if (response.status === 401) {
            // auto logout if 401 response returned from api
            // logout();
            localStorage.removeItem('smav_user');
            // location.reload(true);
         }

         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

function update(id, object) {
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(object)
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/update/${id}`, requestOptions)
      .then(handleResponse)

}

function decodeToken(token) {
   var decoded = jwt_decode(token);
   return (decoded)
}

function getId() {
   return decodeToken(JSON.parse(localStorage.getItem('smav_user')).token).user.id
}

function add(object) {
   const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(object)
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/create`, requestOptions)
      .then(handleResponse)

}
function remove(user_id) {
   const requestOptions = {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/user/delete/${user_id}`, requestOptions)
      .then(handleResponse)

}