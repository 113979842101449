/**
 * User Block Section
*/
import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { IconButton, List, ListItem, Button, ListSubheader, Typography, Popover, Tooltip, Avatar } from '@material-ui/core';

// redux action
import { hulkLogoutUser } from 'actions';
import IntlMessages from 'util/IntlMessages';

const styles = theme => ({
	root: {
		width: '100%',
		minWidth: 300,
		padding: 0,
		'& >a': {
			color: theme.palette.text.primary,
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,0.05)'
			}
		},
		'& .top-dropdown-menu--item': {
			padding: '20px 12px',
			borderTop: `1px solid ${theme.palette.divider}`,
		}
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10)
	},
	avatar: {
		'@media (max-width:1560px)': {
			width: 35,
			height: 35,
		}
	}
});

class HeaderUserBlock extends Component {
	constructor(props) {
		super(props)
		this.confirmationDialog = React.createRef();
		this.state = {
			anchorEl: null,
		};
	}

	//Define function for open dropdown
	handleClick = event => {
		this.setState({
			anchorEl: event.currentTarget,
		});
	};


	//Define function for close dropdown
	handleClose = () => {
		this.setState({
			anchorEl: null,
		});
	};
	/*
	 * Logout User
	 */
	logoutUser = () => {
		this.setState(
			{
				anchorEl: null,
			}
		)
		this.props.hulkLogoutUser();
	}

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		const { classes } = this.props;
		return (
			<div>
				<IconButton aria-describedby={open ? 'simple-popper' : null} variant="contained" color="primary"
					style={{ padding: '6px' }}
					onClick={this.handleClick}>
					<Avatar alt="user-thumb" className={classes.avatar} src={require('assets/Images/avatars/user-4.png')} />
				</IconButton>

				<Popover
					id="simple-popper"
					open={open}
					anchorEl={anchorEl}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Fragment>
						<List className={`${classes.root} top-dropdown-menu`}
							component="nav"
							aria-labelledby="nested-list-subheader"
							subheader={
								<ListSubheader component="div" id="nested-list-subheader">
									<div className="dropdown-header user-info  text-center">
										<Avatar alt="user-thumb" className={classes.large} src={require('assets/Images/avatars/user-4.png')} />
										<Typography variant="body2">{this.props.user?.first_name} {this.props.user?.last_name}</Typography>
										<Typography variant="subtitle2">{this.props.user?.company}</Typography>
										<Button className="btn primary-bg-btn" component={Link} to="/app/user-settings" variant="outlined" color="primary">
											<IntlMessages id="widgets.manage_your_account"></IntlMessages>
										</Button>
									</div>
								</ListSubheader>
							}
						>
							<ListItem component="div" className="top-dropdown-menu--item d-block text-center">
								<Button variant="contained" color="primary" onClick={this.logoutUser}>
									<IntlMessages id="widgets.sign_out"></IntlMessages>
								</Button>
							</ListItem>
						</List>
					</Fragment>
				</Popover>
			</div>
		);
	}
}

const mapStateToProps = ({ settings }) => {
	return settings;
}

export default withRouter(connect(mapStateToProps, {
	hulkLogoutUser
})(withStyles(styles)(HeaderUserBlock)));