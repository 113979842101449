/**
 * Courses Routing File
 */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
    AsyncApiProtocolsComponent,

} from 'routes';

const Api = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/api-protocols`} component={AsyncApiProtocolsComponent}></Route>
      

    </Switch>
)
export default Api;