/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
    CreateInvoiceComponent,
    InvoicesComponent,
    InvoicesDetailsComponent,
    InvoiceExportComponent
} from 'routes'

const Invoices = ({ match }) => (

    < Switch >
        <Route
            path={`${match.url}/details`}
            component={InvoicesDetailsComponent}
        ></Route>
        <Route
            path={`${match.url}/list`}
            component={InvoicesComponent}
        ></Route>
        <Route
            path={`${match.url}/create`}
            component={CreateInvoiceComponent}
        ></Route>
         <Route
            path={`${match.url}/export`}
            component={InvoiceExportComponent}
        ></Route>

    </Switch >
)
export default Invoices
