/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';


//  Dashboard 1
const AsyncDashboard1Component = Loadable({
	loader: () => import("routes/Dashboard/Dashboard1"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("routes/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("routes/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("routes/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const CustomersComponent = Loadable({
	loader: () => import("routes/Customers/Customers"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const CustomerDetailsComponent = Loadable({
	loader: () => import("routes/Customers/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const CustomerExportComponent = Loadable({
	loader: () => import("routes/Customers/Export"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const UsersComponent = Loadable({
	loader: () => import("routes/Users/Users"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const CompaniesComponent = Loadable({
	loader: () => import("routes/companies/List"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const CompaniesExportComponent = Loadable({
	loader: () => import("routes/companies/Export"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const CompanyDetailsComponent = Loadable({
	loader: () => import("routes/companies/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const UpdateCompanyComponent = Loadable({
	loader: () => import("routes/companies/Update"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const UpdateCustomerComponent = Loadable({
	loader: () => import("routes/Customers/Update"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const UpdateUserComponent = Loadable({
	loader: () => import("routes/Users/Update"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

// crm Settings
const AsyncCrmSettingsComponent = Loadable({
	loader: () => import('routes/CrmSettings'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
// crm Settings
const ProductsComponent = Loadable({
	loader: () => import('routes/Products/Products'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const CategoriesComponent = Loadable({
	loader: () => import('routes/Categories/Categories'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const PersonsComponent = Loadable({
	loader: () => import("routes/Person/Persons"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const PersonsExportComponent = Loadable({
	loader: () => import("routes/Person/Export"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const PersonsDetailsComponent = Loadable({
	loader: () => import("routes/Person/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncApiProtocolsComponent = Loadable({
	loader: () => import('routes/Api/Notifications'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const InvoicesComponent = Loadable({
	loader: () => import('routes/invoices/List'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const CreateInvoiceComponent = Loadable({
	loader: () => import('routes/invoices/create'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
const InvoicesDetailsComponent = Loadable({
	loader: () => import('routes/invoices/Details'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const InvoiceExportComponent = Loadable({
	loader: () => import('routes/invoices/Export'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
export {
	AsyncDashboard1Component,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	CustomersComponent,
	CustomerDetailsComponent,
	UpdateCustomerComponent,
	AsyncCrmSettingsComponent,
	CompanyDetailsComponent,
	CompaniesComponent,
	UpdateCompanyComponent,
	ProductsComponent,
	AsyncApiProtocolsComponent,
	CategoriesComponent,
	CreateInvoiceComponent,
	InvoicesComponent,
	InvoicesDetailsComponent,
	UpdateUserComponent,
	UsersComponent,
	PersonsComponent,
	PersonsDetailsComponent,
	PersonsExportComponent,
	CompaniesExportComponent,
	InvoiceExportComponent,
	CustomerExportComponent

};