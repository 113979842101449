
export const personService = {
    getAll,
    getById
};
function getById(person_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/person/getPerson/${person_id}`, requestOptions)
        .then(handleResponse)

}

function getAll() {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/person/getAll`, requestOptions)
        .then(handleResponse)

}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                localStorage.removeItem('smav_user');
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
