/**
 * Blog Routing File
 */
import React from 'react'
import { Route,  Switch } from 'react-router-dom'
import {
    CompaniesComponent,
    CompanyDetailsComponent,
    UpdateCompanyComponent,CompaniesExportComponent
} from 'routes'

const Companies = ({ match }) => (

    < Switch >
        <Route
            path={`${match.url}/details`}
            component={CompanyDetailsComponent}
        ></Route>
        <Route
            path={`${match.url}/List`}
            component={CompaniesComponent}
        ></Route>
        <Route
            path={`${match.url}/export`}
            component={CompaniesExportComponent}
        ></Route>
         <Route
      path={`${match.url}/update`}
      component={UpdateCompanyComponent}
    ></Route>

    </Switch >
)
export default Companies
