/**
 * Courses Routing File
 */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
	AsyncDashboard1Component
   
} from 'routes';

const Dashboard = ({ match }) => (
	<Switch>  
		<Route path={`${match.url}/`} component={AsyncDashboard1Component}></Route>		
	</Switch>
)
export default Dashboard;