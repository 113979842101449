/**
 * Router service file
*/
import Dashboard from 'routes/Dashboard'

// Async component
import {
   AsyncUserSettingsComponent,
   AsyncCrmSettingsComponent
} from 'routes';
import Search from 'routes/Search';
import Customers from 'routes/Customers';
import Companies from 'routes/companies';
import Products from 'routes/Products';
import Api from 'routes/Api';
import Categories from 'routes/Categories/Categories';
import Invoices from 'routes/invoices';
import Users from 'routes/Users';
import Persons from 'routes/Person';
export default [
   {
      path: 'dashboard',
      component: Dashboard
   },
   {
      path: 'customer',
      component: Customers
   },
   {
      path: 'users',
      component: Users
   },
   {
      path: 'user-settings',
      component: AsyncUserSettingsComponent
   },


   {
      path: 'settings',
      component: AsyncCrmSettingsComponent
   },
   {
      path: 'products',
      component: Products
   },
   {
      path: 'categories',
      component: Categories
   },
   {
      path: 'search',
      component: Search
   },
   {
      path: 'companies',
      component: Companies
   },
   {
      path: 'person',
      component: Persons
   },
   {
      path: 'invoices',
      component: Invoices

   },
   {
      path: 'api',
      component: Api

   }
]