/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  PersonsComponent,
  PersonsDetailsComponent,
  PersonsExportComponent
} from 'routes'

const Persons = ({ match }) => (

  < Switch >
    <Route
      path={`${match.url}/List`}
      component={PersonsComponent}
    ></Route>
     <Route
      path={`${match.url}/export`}
      component={PersonsExportComponent}
    ></Route>
      <Route
      path={`${match.url}/details`}
      component={PersonsDetailsComponent}
    ></Route>

  </Switch >
)
export default Persons
