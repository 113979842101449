/**
 * Forget Password
*/
import React, { Component } from 'react';
import { TextField, Button, Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from 'components/GlobalComponents';
import { Link } from 'react-router-dom';
import { userService } from './../../_services'
class ForgotPasswordFirebase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: ""
		}
	}

	handlechange(event) {
		this.setState({ email: event.target.value })
	}

	resetPasswordRequest() {
		userService.resetPasswordRequest(this.state.email).then(result => {
			this.props.history.push('/signin')
		}).catch(error => {
			console.log(error)

		})

	}
	render() {
		return (
			<div>
				<div className="session-wrapper session-wrapper-v2">
					<Box className="session-box" mx="auto" display="flex" justifyContent="center" alignItems="center">
						<Box width="100%">
						<Box textAlign="center" className="session-logo" >
								
							</Box>
							<CustomCard>
								<form className="login-form text-center">
									<Typography variant="h6" color="textPrimary" className="title">E-MAILADRESSE BESTÄTIGEN</Typography>
									<Box my={2}>
										<TextField
											required
											fullWidth
											variant="outlined"
											id="username"
											type="email"
											name="email"
											placeholder="Bitte geben Sie Ihre E-Mailadresse an mit der Sie sich angemeldet haben ein."
											className="outlined-input"
											onChange={(event) => this.handlechange(event)}
											value={this.state.email}
										/>
									</Box>
									<Box py={3}>
										<Button
											color="primary"
											className="btn-block blockBtn w-100"
											variant="contained"
											size="large"
											onClick={() => this.resetPasswordRequest()}
										>
											Abschicken
										</Button>
									</Box>
									<Divider></Divider>
									<Box pt={2} fontSize="subtitle2.fontSize">
										<Box style={{ cursor: 'pointer' }} color="primary.main" component={Link} to="/signin" >Zurück zur Anmeldung</Box>

									</Box>
								</form>
							</CustomCard>
						</Box>
					</Box>
				</div>
			</div>
		);
	}
}

export default (ForgotPasswordFirebase);