

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from 'components/GlobalComponents';

// redux action
import {
	onEmailChanged,
	onPasswordChanged,
	signinUserWithJwt
} from 'actions';

import Auth from '../../Auth/Auth';
const auth = new Auth();

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}
class Signin extends Component {
	//constructor
	constructor(props) {
		super(props);
		this.state = {
			formErrors: {
				blankEmail: false,
				invalidEmail: false,
				blankPassword: false
			},
			value: 0
		}
	}


	/**
	 * Function to detect email changes
	 */
	onEmailChanged(e) {
		let fieldValidationErrors = this.state.formErrors;
		fieldValidationErrors.blankEmail = false;
		// fieldValidationErrors.invalidEmail = false;
		this.setState({ formErrors: fieldValidationErrors })
		this.props.onEmailChanged(e.target.value);
	}

	/**
	 * Function to detect login password changes
	 */
	onPasswordChanged(e) {
		let fieldValidationErrors = this.state.formErrors;
		fieldValidationErrors.blankPassword = false;
		this.setState({ formErrors: fieldValidationErrors });
		this.props.onPasswordChanged(e.target.value);
	}

	/**
	* Function is use for check the email validation.
	*/
	validateEmail(email) {
		let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
		return emailValid;
	}

	/**
	 * Function to show error
	 */
	renderError() {
		if (this.props.error) {
			return (
				<div style={{ backgroundColor: 'white' }}>
					<TextField>{this.props.error}</TextField>
				</div>
			);
		}
	}

	/**
	 * On User Sign Up
	 */
	onUserSignUp() {
		this.props.history.push('/signup');
	}
	onForgotPassword() {
		this.props.history.push('/forgot-password');
	}

	loginAuth0() {
		auth.login();
	}

	onJwtLogin(e) {
		// e.preventDefault();
		const { email, password } = this.props;
		this.setState({ submitted: true });
		// const { dispatch } = this.props;
		if (email && password) {
			this.props.signinUserWithJwt(this.props, this.props.history)
		}
	}
	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { blankEmail, blankPassword, invalidEmail } = this.state.formErrors;
		const { email, password, error } = this.props;
		return (
			<div>
				<div className="session-wrapper session-wrapper-v2">
					<Box className="session-box" mx="auto" display="flex" justifyContent="center" alignItems="center">
						<Box width="100%">

						<Box textAlign="center" className="session-logo" >
								
							</Box>
							<TabPanel value={this.state.value} index={0} className="log-box">
								<CustomCard>
									<form className="login-form text-center">
										<Typography variant="subtitle2" >Melden Sie sich an, um fortzufahren
											:</Typography>
										<Typography variant="subtitle2" color="textPrimary" className="fw-500"> DETEKTEI DUDZUS</Typography>
										<Box my={3}>
										
											<TextField
												required
												fullWidth
												variant="outlined"
												id="email"
												type="email"
												name="email"
												placeholder="Bitte geben Sie Ihre E-Mail-Adresse ein."
												className="outlined-input"
												value={email}
												onChange={(email) => this.onEmailChanged(email)}
												error={blankEmail || invalidEmail || error ? true : false}
											/>
											{blankEmail &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>E-Mail darf nicht leer sein.</Box>
											}
											{!blankEmail && invalidEmail &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Die E-Mail-Adresse ist schlecht formatiert.</Box>
											}
										</Box>
										<Box mb={3}>
											<TextField
												required
												fullWidth
												variant="outlined"
												id="login-password"
												placeholder="Bitte geben Sie Ihr Login-Passwort ein."
												className="outlined-input"
												type="password"
												value={password}
												error={blankPassword || error ? true : false}
												onChange={this.onPasswordChanged.bind(this)}
											/>
											{blankPassword &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Kennwort darf nicht leer sein</Box>
											}
										</Box>
										<Box mb="20px">
											<Button
												color="primary"
												className="btn-block blockBtn w-100"
												variant="contained"
												size="large"
												onClick={() => this.onJwtLogin()}
											>
												Anmelden
											</Button>
										</Box>



										<Divider></Divider>
										<Box display="flex" justifyContent="center" alignItems="center" pt={2}>
											<Box style={{ cursor: 'pointer' }}  onClick={() => this.onForgotPassword()}>Passwort vergessen?</Box>


										</Box>
									</form>
								</CustomCard>
							</TabPanel>
							<TabPanel value={this.state.value} index={1} className="log-box">
								<CustomCard>
									<form className="login-form text-center">
										<Typography variant="subtitle2" >Log in to continue to :</Typography>
										<Typography variant="subtitle2" color="textPrimary" className="fw-500">Sofort Handelsregister API</Typography>
										<Box my={3}>
											<TextField
												required
												fullWidth
												variant="outlined"
												id="email"
												type="email"
												name="email"
												placeholder="Please enter your email address."
												className="outlined-input"
												value={email}
												onChange={(email) => this.onEmailChanged(email)}

											/>
										</Box>
										<Box mb={3}>
											<TextField
												required
												fullWidth
												variant="outlined"
												id="login-password"
												placeholder="Please enter your login password."
												className="outlined-input"
												type="password"
												value={password}
												onChange={this.onPasswordChanged.bind(this)}
											/>

										</Box>
										<Box mb={1}>
											<Button
												color="primary"
												className="btn-block blockBtn w-100"
												variant="contained"
												size="large"
												onClick={() => this.onJwtLogin()}
											>
												Sign In With JWT
											</Button>
										</Box>
									</form>
								</CustomCard>
							</TabPanel>
						</Box>
					</Box>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ authUser, settings }) => {
	const { loading, email, password, error } = authUser;
	const { isDarkModeActive } = settings;
	return { loading, email, password, error, isDarkModeActive };
};

export default connect(mapStateToProps, {
	onEmailChanged,
	onPasswordChanged,
	signinUserWithJwt
})(Signin);
