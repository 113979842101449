
export const protocolService = {
    getAll,
    create,
    getByCustomer
};

// get protocols list
function getAll() {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${process.env.REACT_APP_API_URL}/protocol/all`, requestOptions)
        .then(handleResponse)
}

// create protocol
function create(object) {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/protocol/create`, requestOptions)
        .then(handleResponse)
}

// get protocols by customer
function getByCustomer(customer_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${process.env.REACT_APP_API_URL}/protocol/all/${customer_id}`, requestOptions)
        .then(handleResponse)
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


